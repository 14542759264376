import { PhoneIcon, EmailIcon } from "../images/index";

export function ContactSidebar({ formType }: { formType: string }) {
    return (
        <div>
            <div className={`sticky top-0 text-[#273B29] ${formType === "vanity" ? "2xl:mt-[550px] xl:mt-[520px] lg:mt-[500px]" : "2xl:mt-[700px] xl:mt-[580px] lg:mt-[460px]"}`}>
                <div className="bg-[#DAE7D0] rounded-3xl p-10">
                    <h4 className="text-2xl font-semibold pb-4">Contact Info</h4>
                    <p className="pb-4">If you have any questions on how to fill out the form please contact us:</p>
                    <p className="pb-3">
                        <img src={PhoneIcon} alt="Phone" className="inline-block relative bottom-px pr-2" />
                        (888) 510-0059
                    </p>                    <p>
                        <img src={EmailIcon} alt="Email" className="inline-block relative bottom-px pr-2" />
                        <a href="mailto:care@tln.care">care@tln.care</a>
                    </p>
                </div>
            </div>
        </div>
    )
}