import * as React from 'react';
import { Dayjs } from 'dayjs';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import Box from '@mui/material/Box';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import {
    unstable_useDateField as useDateField,
    UseDateFieldProps,
} from '@mui/x-date-pickers/DateField';
import {
    DateFieldSlotsComponent,
    DateFieldSlotsComponentsProps,
} from '@mui/x-date-pickers/DateField/DateField.types';
import { useClearableField } from '@mui/x-date-pickers/hooks';
import {
    BaseSingleInputFieldProps,
    DateValidationError,
    DesktopDatePicker,
    FieldSection,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useRef } from 'react';

const INVALID_DATE_STR = 'Invalid Date';

interface TlnFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: React.ReactNode;
    inputRef?: React.Ref<any>;
    InputProps?: {
        ref?: React.Ref<any>;
        endAdornment?: React.ReactNode;
        startAdornment?: React.ReactNode;
    };
    error?: boolean;
    focused?: boolean;
    ownerState?: any;
    sx?: any;
}

type TlnFieldComponent = ((
    props: TlnFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

const TlnField = React.forwardRef(
    (props: TlnFieldProps, ref: React.Ref<HTMLDivElement>) => {
        const {
            disabled,
            id,
            label,
            inputRef,
            InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
            // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
            error,
            focused,
            ownerState,
            sx,
            ...other
        } = props;

        const handleRef = useForkRef(containerRef, ref);
        const [isFocused, setIsFocused] = React.useState(false);
        const val = React.useMemo(() => {
            if (isFocused) {
                return props.value
            } else {
                if (props.value && /\d/.test(props.value.toString())) {
                    return props.value
                } else {
                    return ''
                }
            }
        }, [isFocused, props.value])

        return (
            <Box
                sx={{ ...(sx || {}), display: 'flex', alignItems: 'center' }}
                id={id}
                ref={handleRef}
            >
                {startAdornment}
                <input
                    disabled={disabled}
                    ref={inputRef}
                    {...other}
                    onFocus={e => {
                        setIsFocused(true);
                        if (props.onFocus)
                            props.onFocus(e);
                    }}
                    onBlur={e => {
                        setIsFocused(false);
                        if (props.onBlur)
                            props.onBlur(e);
                    }}
                    value={val}
                    placeholder={props.label?.toString()}
                />
            </Box>
        );
    },
) as TlnFieldComponent;

interface TlnDateFieldProps
    extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
        Dayjs | null,
        Dayjs,
        FieldSection,
        DateValidationError
    > { }

const TlnDateField = React.forwardRef(
    (props: TlnDateFieldProps, ref: React.Ref<HTMLDivElement>) => {
        const {
            inputRef: externalInputRef,
            slots,
            slotProps,
            ...textFieldProps
        } = props;

        const {
            onClear,
            clearable,
            ref: inputRef,
            ...fieldProps
        } = useDateField<Dayjs, typeof textFieldProps>({
            props: textFieldProps,
            inputRef: externalInputRef,
        });

        /* If you don't need a clear button, you can skip the use of this hook */
        const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
            useClearableField<
                {},
                typeof textFieldProps.InputProps,
                DateFieldSlotsComponent,
                DateFieldSlotsComponentsProps<Dayjs>
            >({
                onClear,
                clearable,
                fieldProps,
                InputProps: fieldProps.InputProps,
                slots,
                slotProps,
            });
        return (
            <TlnField
                ref={ref}
                inputRef={inputRef}
                {...processedFieldProps}
                InputProps={ProcessedInputProps}
            />
        );
    },
);

type TextDateFieldProps =
    Omit<DatePickerProps<Dayjs>, 'value' | 'onChange'>
    & {
        value?: string,
        onChange?: (val: string) => void,
        onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
        required?: boolean;
    }

export const TextDateField = React.forwardRef(
    (props: TextDateFieldProps, ref: React.Ref<HTMLDivElement>) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [isFirstRender, setIsFirstRender] = React.useState(true);

        React.useEffect(() => {
            if (inputRef.current && isFirstRender && props.required && !props.value) {
                inputRef.current.setCustomValidity('This field is required.');
                setIsFirstRender(false);
            }
        }, [inputRef.current])

        return (
            <DesktopDatePicker
                ref={ref}
                {...props}
                value={dayjs(props.value)}
                onChange={e => {
                    if (props.onChange && e)
                        if (e.format('YYYY-MM-DD') !== INVALID_DATE_STR)
                            props.onChange(e.format('YYYY-MM-DD'));
                        else
                            props.onChange('');
                }}
                //@ts-ignore
                slots={{ field: TlnDateField, ...props.slots }}
                slotProps={{
                    field: {
                        onBlur: () => {
                            if (props.onBlur && inputRef.current) {
                                props.onBlur(({ target: inputRef.current } as any))
                            }
                        },
                        onKeyDown: () => {
                            inputRef?.current?.setCustomValidity('');
                        },
                        inputRef: inputRef
                    }
                }}
            />
        );
    },
);
