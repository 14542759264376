import { FormWrapper } from "./components/FormWrapper";
import { PatternFormat } from "react-number-format";
import { StepOneData } from "./types";
import { useEffect } from "react";
import { useValidatorContext } from "./hooks/useValidator";
import { DateFormatValid, GetAge, RequiredValid } from "./utils";
import { TextDateField } from "./components/TextDateField";

type StepOneProps = StepOneData & {
    updateFields: (fields: Partial<StepOneData>) => void
    formType: string
}

export function StepOneForm({
    firstName,
    lastName,
    preferredName,
    email,
    phone,
    dateOfBirth,
    street,
    city,
    state,
    zip,
    country,
    babyArrived,
    twins,
    babyFirst,
    babyLast,
    babyDOB,
    babySex,
    dueDate,
    updateFields,
    formType
}: StepOneProps) {
    const statesArray = [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'District Of Columbia',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Puerto Rico',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ];

    const countriesArray = [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cura�ao",
        "Cyprus",
        "Czechia",
        "C�te d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "R�union",
        "Saint Barth�lemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, the United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Viet Nam",
        "Virgin Islands, British",
        "Virgin Islands, U.S.",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
        "�land Islands"
    ];

    const mainDoorInformationalCopy = (
        <div className='text-primary-green'>
            <h4 className="lg:text-2xl text-lg mt-8 font-semibold">You're in good hands. Let's get you the care you deserve.</h4>
            <div className="mt-3">Whether you're prenatal, weaning, or facing feeding challenges in between, we're here to help you throughout your breastfeeding journey.
                The Lactation Network works with your health plan to provide you with in-home, in-office, or telehealth appointments with an expert International Board Certified Lactation Consultant (IBCLC). IBCLCs are the world's highest credentialed lactation consultants and will support your family as you address your breastfeeding needs.</div>
            <div className="mt-3 font-semibold">When you submit your personal and insurance information in the form below, you'll allow us to:</div>
            <ul className="list-disc mt-3 ml-6">
                <li>Check your insurance coverage so you can receive care at no cost to you.</li>
                <li>Help you access an IBCLC to serve you and your family's breastfeeding needs.</li>
            </ul>
        </div>
    )

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const methods = useValidatorContext<StepOneData>();

    return (
        <>
            {formType === 'main' && mainDoorInformationalCopy}
            <FormWrapper title={formType !== 'main' ? "Introduce yourself" : ''}>
                <h4 className="lg:text-2xl text-lg lg:mt-4 mt-2 mb-2 font-semibold">Lactating Parent's Information</h4>
                <div className="md:grid grid-cols-2 gap-6">
                    <input
                        required
                        maxLength={256}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={firstName}
                        onChange={e => {
                            updateFields({ firstName: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="First"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />
                    <input
                        required
                        maxLength={256}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={lastName}
                        onChange={e => {
                            updateFields({ lastName: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Last"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />
                </div>
                <input
                    type="text"
                    value={preferredName}
                    maxLength={40}
                    onChange={e => updateFields({ preferredName: e.target.value })}
                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    placeholder="Preferred Name"
                />
                <input
                    required
                    maxLength={256}
                    type="email"
                    pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                    value={email}
                    onChange={e => {
                        updateFields({ email: e.target.value });
                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                            (e.target as HTMLInputElement).setCustomValidity('Please enter a valid email address. Ex: user@domain.com');
                        } else {
                            (e.target as HTMLInputElement).setCustomValidity('');
                        }
                    }}
                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    placeholder="Email address"
                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Please enter a valid email address. Ex: user@domain.com')}
                />

                <PatternFormat
                    required
                    maxLength={15}
                    pattern=".*\S+.*"
                    title="This field is required."
                    type="text"
                    format="(###) ###-####"
                    value={phone}
                    onChange={e => {
                        updateFields({ phone: e.target.value });
                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                        } else {
                            (e.target as HTMLInputElement).setCustomValidity('');
                        }
                    }}
                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    placeholder="Mobile phone number"
                    onInvalid={(e: any) => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                />
                <TextDateField
                    required
                    value={dateOfBirth}
                    onChange={e => updateFields({ dateOfBirth: e })}
                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    label="Lactating parent's date of birth"
                    {...methods.registerField('dateOfBirth', {
                        validate: (str) => {
                            if (!RequiredValid(str)) {
                                return 'This field is required.';
                            }
                            if (!DateFormatValid(str) || GetAge(str) < 10) {
                                return `Lactating parent's date of birth is invalid.`;
                            }
                        },
                        onBlurSetHtmlCustomValidity: true,
                        validateOnBlur: true
                    })}
                />
                <input
                    required
                    maxLength={512}
                    pattern=".*\S+.*"
                    title="This field is required."
                    type="text"
                    value={street}
                    onChange={e => {
                        updateFields({ street: e.target.value });
                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                        } else {
                            (e.target as HTMLInputElement).setCustomValidity('');
                        }
                    }}
                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    placeholder="Street address"
                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                />

                <div className="md:grid grid-cols-2 gap-6">
                    <input
                        required
                        maxLength={256}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={city}
                        onChange={e => {
                            updateFields({ city: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="City"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />
                    {country === "United States" ? (
                        <select
                            required
                            value={state}
                            onChange={e => updateFields({ state: e.target.value })}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        >
                            <option value="">State/Province/Region</option>
                            {statesArray
                                .map((stateName) => {
                                    return <option key={stateName}>{stateName}</option>
                                })}
                        </select>
                    ) : (
                        <input
                            required
                            maxLength={256}
                            pattern=".*\S+.*"
                            title="This field is required."
                            type="text"
                            value={state}
                            onChange={e => {
                                updateFields({ state: e.target.value });
                                if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                    (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                } else {
                                    (e.target as HTMLInputElement).setCustomValidity('');
                                }
                            }}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                            placeholder="State/Province/Region"
                            onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                        />
                    )}
                </div>
                <div className="md:grid grid-cols-2 gap-6">
                    <PatternFormat
                        required
                        maxLength={10}
                        type="text"
                        format="#####"
                        pattern="[0-9]{5}"
                        value={zip}
                        onChange={e => updateFields({ zip: e.target.value })}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Zip / Postal code"
                    />
                    <select
                        required
                        value={country}
                        onChange={e => updateFields({ country: e.target.value, state: "" })}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    >
                        {countriesArray
                            .map((countryName) => {
                                return <option key={countryName}>{countryName}</option>
                            })}
                    </select>
                </div>

                <h4 className="lg:text-2xl text-lg lg:mt-6 mt-2 mb-2 font-semibold">Baby's Information</h4>

                <p className="mb-3">Has your baby arrived?</p>
                <div>
                    <label className="mb-2">
                        <input
                            required
                            type="radio"
                            name="babyArrived"
                            value="True"
                            checked={babyArrived === "True"}
                            onChange={e => updateFields({ babyArrived: e.target.value })}
                        /> Yes
                    </label>
                    <label className="mb-2">
                        <input
                            required
                            type="radio"
                            name="babyArrived"
                            value="False"
                            checked={babyArrived === "False"}
                            onChange={e => updateFields({ babyArrived: e.target.value })}
                        /> No
                    </label>
                </div>

                {babyArrived === "True" && (
                    <>
                        <input
                            required
                            maxLength={256}
                            pattern=".*\S+.*"
                            title="This field is required."
                            type="text"
                            value={babyFirst}
                            onChange={e => {
                                updateFields({ babyFirst: e.target.value });
                                if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                    (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                } else {
                                    (e.target as HTMLInputElement).setCustomValidity('');
                                }
                            }}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                            placeholder="First Name"
                            onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                        />
                        <input
                            required
                            maxLength={256}
                            pattern=".*\S+.*"
                            title="This field is required."
                            type="text"
                            value={babyLast}
                            onChange={e => {
                                updateFields({ babyLast: e.target.value });
                                if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                    (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                } else {
                                    (e.target as HTMLInputElement).setCustomValidity('');
                                }
                            }}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                            placeholder="Last Name"
                            onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                        />
                        <TextDateField
                            required
                            value={babyDOB}
                            onChange={e => updateFields({ babyDOB: e })}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                            label="Date of Birth"
                            {...methods.registerField('babyDOB', {
                                validate: (str) => {
                                    if (!RequiredValid(str)) {
                                        return 'This field is required.';
                                    }
                                    if (!DateFormatValid(str) || GetAge(str) > 10 || GetAge(str) < 0) {
                                        return `This doesn't appear to be a valid date of birth for a baby. Please try again.`;
                                    }
                                },
                                onBlurSetHtmlCustomValidity: true,
                                validateOnBlur: true
                            })}
                        />
                        <select
                            required
                            value={babySex}
                            onChange={e => updateFields({ babySex: e.target.value })}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        >
                            <option value="">Assigned sex at birth</option>
                            <option value="M">M</option>
                            <option value="F">F</option>
                            <option value="Don't know yet">Don't know yet</option>
                        </select>
                    </>
                )}

                {babyArrived === "False" && (
                    <>
                        <TextDateField
                            required
                            value={dueDate}
                            onChange={e => updateFields({ dueDate: e })}
                            className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                            label="Expected due date"
                            {...methods.registerField('dueDate', {
                                validate: (str) => {
                                    if (!RequiredValid(str)) {
                                        return 'This field is required.';
                                    }
                                    if (!DateFormatValid(str)) {
                                        return 'Due date is invalid.';
                                    }
                                },
                                onBlurSetHtmlCustomValidity: true,
                                validateOnBlur: true
                            })}
                        />
                    </>
                )}

                <p className="mb-3">Are you having twins/multiples?*</p>
                <div>
                    <label className="mb-2">
                        <input
                            required
                            type="radio"
                            name="twins"
                            value="yes"
                            checked={twins === "yes"}
                            onChange={e => updateFields({ twins: e.target.value })}
                        /> Yes
                    </label>
                    <label className="mb-2">
                        <input
                            required
                            type="radio"
                            name="twins"
                            value="no"
                            checked={twins === "no"}
                            onChange={e => updateFields({ twins: e.target.value })}
                        /> No
                    </label>
                </div>
            </FormWrapper>
        </>
    )
}