import { FormWrapper } from "./components/FormWrapper";
import { StepThreeData } from "./types";
import { useEffect } from "react";

type StepThreeProps = StepThreeData & {
    updateFields: (fields: Partial<StepThreeData>) => void
    primaryNoTelehealth: boolean,
    babyInsuranceNoTelehealth: boolean,
    babyOnPrimary: string,
    formType: string
}

export function StepThreeForm({
    urgency,
    careLocation,
    pairing,
    consultantName,
    primaryNoTelehealth,
    babyInsuranceNoTelehealth,
    babyOnPrimary,
    smsAcknowledgement,
    privacyPolicyAcknowledgement,
    aobAcknowledgement,
    updateFields,
    formType,
}: StepThreeProps) {

    const noTelehealth = babyOnPrimary === 'yes' ? primaryNoTelehealth : (primaryNoTelehealth && babyInsuranceNoTelehealth);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormWrapper title="Share your lactation care preferences">
            {(formType === "nbf" || formType === "main") && (
                <>
                    <p className="mb-3">Do you have a TLN lactation consultant (IBCLC)? </p>
                    <div>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="pairing"
                                value="tln_picks"
                                checked={pairing === "tln_picks"}
                                onChange={e => updateFields({ pairing: e.target.value })}
                            />No, I would like the TLN team to help me find a lactation consultant (IBCLC)
                        </label>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="pairing"
                                value="user_picks"
                                checked={pairing === "user_picks"}
                                onChange={e => updateFields({ pairing: e.target.value })}
                            />Yes, I already have a TLN IBCLC that I'd like to work with
                        </label>
                    </div>

                    {pairing === "user_picks" && (
                        <>
                            <input
                                required
                                maxLength={256}
                                pattern=".*\S+.*"
                                title="This field is required."
                                type="text"
                                value={consultantName}
                                onChange={e => {
                                    updateFields({ consultantName: e.target.value });
                                    if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                        (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                    } else {
                                        (e.target as HTMLInputElement).setCustomValidity('');
                                    }
                                }}
                                className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                placeholder="Lactation consultant (IBCLC) first and last name"
                                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                            />
                        </>
                    )}
                </>
            )}

            <p className="mt-4 mb-3">How urgent is your need for a visit?</p>
            <div>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="urgency"
                        value="Emergent - need to be seen in the next 36 hours"
                        checked={urgency === "Emergent - need to be seen in the next 36 hours"}
                        onChange={e => updateFields({ urgency: e.target.value })}
                    /> Emergent - need to be seen in the next 36 hours
                </label>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="urgency"
                        value="Urgent - need to be seen within the next 72 hours"
                        checked={urgency === "Urgent - need to be seen within the next 72 hours"}
                        onChange={e => updateFields({ urgency: e.target.value })}
                    /> Urgent - need to be seen within the next 72 hours
                </label>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="urgency"
                        value="Non-Urgent - can wait 5 days for a visit"
                        checked={urgency === "Non-Urgent - can wait 5 days for a visit"}
                        onChange={e => updateFields({ urgency: e.target.value })}
                    /> Non-Urgent - can wait 5 days for a visit
                </label>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="urgency"
                        value="Planning Ahead - can wait more than 5 days for a visit"
                        checked={urgency === "Planning Ahead - can wait more than 5 days for a visit"}
                        onChange={e => updateFields({ urgency: e.target.value })}
                    /> Planning ahead - can wait more than 5 days for a visit
                </label>
            </div>

            {formType !== "vanity" && (
                <>
                    <p className="mt-4 mb-3">Where do you prefer to receive care?</p>
                    <div>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="careLocation"
                                value="Home Visit"
                                checked={careLocation === "Home Visit"}
                                onChange={e => updateFields({ careLocation: e.target.value })}
                            /> In home
                        </label>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="careLocation"
                                value="Office Visit"
                                checked={careLocation === "Office Visit"}
                                onChange={e => updateFields({ careLocation: e.target.value })}
                            /> In office
                        </label>
                        <label className={`mb-2 ${noTelehealth && "text-[#7E8288]"}`}>
                            <input
                                required
                                type="radio"
                                name="careLocation"
                                value="Telehealth Visit"
                                checked={careLocation === "Telehealth Visit"}
                                onChange={e => updateFields({ careLocation: e.target.value })}
                                disabled={noTelehealth}
                            /> Telehealth
                        </label>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="careLocation"
                                value="No preference"
                                checked={careLocation === "No preference"}
                                onChange={e => updateFields({ careLocation: e.target.value })}
                            /> No preference
                        </label>

                        {noTelehealth && (
                            <p className="mt-4"><strong>You are only covered for in home or in person care</strong></p>
                        )}
                    </div>
                </>
            )}

            <p className="mt-4 mb-2">SMS Acknowledgement</p>
            <div>
                <label className="custom-checkbox">
                    I'm aware that The Lactation Network may provide me with notices, including those related to my order, or use of
                    lactation services by SMS, MMS, or text message. Standard message and data rates apply. I understand that I can
                    revoke this consent at any time.
                    <input
                        required
                        type="checkbox"
                        name="smsAcknowledgement"
                        checked={smsAcknowledgement}
                        onChange={e => updateFields({ smsAcknowledgement: e.target.checked })}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>

            <p className="mt-4 mb-2">Privacy Policy Acknowledgement</p>
            <div>
                <label className="custom-checkbox">
                    I acknowledge that my personal data will be used to process product orders, verify eligibility for lactation services,
                    support my experience throughout this website, and for other purposes described in
                    our <a href="https://lactationnetwork.com/privacy-policy" target="_blank" className="underline" rel="noreferrer">Privacy Policy</a> and <a href="https://lactationnetwork.com/hipaa" target="_blank" className="underline" rel="noreferrer">HIPAA Notice of Privacy Practices</a>.
                    <input
                        required
                        type="checkbox"
                        name="privacyPolicyAcknowledgement"
                        checked={privacyPolicyAcknowledgement}
                        onChange={e => updateFields({ privacyPolicyAcknowledgement: e.target.checked })}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>

            <p className="mt-4 mb-2">Assignment of Benefits (AOB) Acknowledgement</p>
            <div>
                <label className="custom-checkbox">
                    By selecting this box, I acknowledge that I have reviewed and agree with the&nbsp;
                    <a href="https://tlnshared.blob.core.windows.net/public-files/TLN%20LACTATION%20AOB%20v2.pdf" target="_blank" className="underline" rel="noreferrer">AOB terms provided here</a>.
                    I also understand that these terms will only go into effect if and when I have my first consultation with a TLN IBCLC. Following my first consultation,
                    I authorize TLN to file claims for patients who received services through TLN (including lactating parent and baby/babies), and for my insurance to pay
                    TLN directly for any payments due for services provided.
                    <input
                        required
                        type="checkbox"
                        name="aobAcknowledgement"
                        checked={aobAcknowledgement}
                        onChange={e => {
                            updateFields({ aobAcknowledgement: e.target.checked });
                            (e.target as HTMLInputElement).setCustomValidity('');
                        }}
                        onInvalid={e =>
                            (e.target as HTMLInputElement).setCustomValidity(
                                'Patients who decline to accept the above AOB terms cannot receive care through The Lactation Network.\n' +
                                'You must select these acknowledgments before you can schedule with TLN providers.'
                            )
                        }
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        </FormWrapper>
    )
}