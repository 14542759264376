import { FormWrapper } from "./components/FormWrapper";
import Dropzone from "react-dropzone";
import { useEffect } from "react";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { StepTwoData, SelectOption, InsuranceProvider, EmployerSelectOption } from "./types";
import { HelpIcon } from "./images/index";
import { useValidatorContext } from "./hooks/useValidator";
import { DateFormatValid, RequiredValid } from "./utils";
import { TextDateField } from "./components/TextDateField";
import { Tooltip } from "react-tooltip";

type StepTwoProps = StepTwoData & {
    updateFields: (fields: Partial<StepTwoData>) => void
    insOptions: SelectOption[]
    employerOptions: EmployerSelectOption[]
    insProviders: InsuranceProvider[]
}

export function StepTwoForm({
    primaryPlan,
    primaryPlanId,
    primaryIsLocked,
    primaryNumber,
    primaryGroup,
    primaryReferral,
    primaryFront,
    primaryBack,
    subscriberRelationship,
    subscriberFirstName,
    subscriberLastName,
    subscriberDateOfBirth,
    primaryProvidedByEmployer,
    babyOnPrimary,
    babyInsurancePlan,
    babyInsurancePlanId,
    babyInsuranceNumber,
    babyInsuranceGroup,
    babyInsuranceReferral,
    babyInsuranceHolderFirstName,
    babyInsuranceHolderLastName,
    babyInsuranceHolderDob,
    babyInsuranceFront,
    babyInsuranceBack,
    haveSecondary,
    secondaryPlan,
    secondaryPlanId,
    secondaryNumber,
    secondaryGroup,
    secondaryFront,
    secondaryBack,
    employer,
    employerAliasSelected,
    employerId,
    hasEmployerCode,
    employerCode,
    vaReferralNumber,
    vaCardFront,
    vaCardBack,
    updateFields,
    insOptions,
    employerOptions,
    insProviders,
    formType
}: StepTwoProps) {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const filterOptions = (inputValue: string) => {
        return employerOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    const promiseOptions = (inputValue: string) =>
        new Promise<SelectOption[]>((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue));
            }, 500);
        })

    const methods = useValidatorContext<StepTwoData>();

    return (
        <FormWrapper title="Provide details about your coverage">
            <h4 className="lg:text-2xl text-lg lg:mt-6 mt-2 mb-2 font-semibold">Insurance Information</h4>

            <Select
                required
                options={insOptions}
                onChange={option => {
                    if (option) {
                        const provider = insProviders.filter(p => p.id === option.value)[0];
                        updateFields({
                            primaryPlan: option.label,
                            primaryPlanId: option.value,
                            primaryNoTelehealth: provider.noTelehealth,
                            coverageInformation: provider.coverageInformation
                        });
                    }
                }}
                defaultValue={
                    primaryPlan ? {
                        label: primaryPlan,
                        value: primaryPlanId
                    } : null
                }
                className="rounded-full border-[#2D3C2C] border w-full px-4 bg-transparent my-3"
                placeholder="Primary Insurance plan for lactating parent"
                styles={{
                    control: base => ({
                        ...base,
                        border: "0px solid black",
                        backgroundColor: "transparent",
                        outline: "none",
                        boxShadow: "none",
                    }),
                    valueContainer: base => ({
                        ...base,
                        padding: 0
                    }),
                    menu: base => ({
                        ...base,
                        width: "95%"
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                        ...base,
                        backgroundColor: isFocused ? "lightblue" : "transparent",
                        color: "black"
                    })
                }}
                isDisabled={primaryIsLocked}
            />

            {/* TODO: Needs regex or other improved way to match this */}
            {!primaryPlan.toLowerCase().startsWith("veterans ccn") && (
                <>
                    <input
                        required
                        maxLength={40}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={primaryNumber}
                        onChange={e => {
                            updateFields({ primaryNumber: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Lactating Parent's Primary Insurance Policy Number (with prefix, no spaces)"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />

                    <input
                        type="text"
                        maxLength={40}
                        value={primaryGroup}
                        onChange={e => updateFields({ primaryGroup: e.target.value })}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="OPTIONAL: Lactating Parent's Primary Group Number (with prefix, if applicable)"
                    />

                    <p className="mb-1">
                        Does your plan require a referral?

                        <a data-tooltip-id="referral-tooltip" data-tooltip-content="HMO and POS plans often require a referral" >
                            <img src={HelpIcon} alt="Help" className="inline-block mt-1 h-[20px]" />
                        </a>

                    </p>
                    <div className="mb-3">
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="primaryReferral"
                                value="Yes"
                                checked={primaryReferral === "Yes"}
                                onChange={e => updateFields({ primaryReferral: e.target.value })}
                            /> Yes
                        </label>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="primaryReferral"
                                value="No"
                                checked={primaryReferral === "No"}
                                onChange={e => updateFields({ primaryReferral: e.target.value })}
                            /> No
                        </label>
                    </div>

                    <p className="mb-2 font-medium">Please upload the front photo of your insurance card</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ primaryFront: acceptedFiles[0] });
                            const errorElement = document.getElementById("primary-front-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("primary-front-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps({ required: primaryFront ? false : true })}
                                    style={{
                                        display: 'initial',
                                        opacity: '0',
                                        position: 'absolute',
                                        zIndex: -1
                                    }}
                                />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="primary-front-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {primaryFront && (
                            <>
                                {primaryFront.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ primaryFront: undefined })}>Remove</small>
                            </>
                        )}
                    </p>

                    <p className="mb-2 font-medium">Please upload the back of your insurance card</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ primaryBack: acceptedFiles[0] });
                            const errorElement = document.getElementById("primary-back-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("primary-back-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps({ required: primaryBack ? false : true })}
                                    style={{
                                        display: 'initial',
                                        opacity: '0',
                                        position: 'absolute',
                                        zIndex: -1
                                    }}
                                />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="primary-back-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {primaryBack && (
                            <>
                                {primaryBack.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ primaryBack: undefined })}>Remove</small>
                            </>
                        )}
                    </p>

                    <select
                        required
                        value={subscriberRelationship}
                        onChange={e => updateFields({ subscriberRelationship: e.target.value })}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                    >
                        <option value="">Relationship to subscriber</option>
                        <option>Self</option>
                        <option>Spouse</option>
                        <option>Child</option>
                    </select>

                    {subscriberRelationship !== "" && subscriberRelationship !== "Self" && (
                        <>
                            <div className="md:grid grid-cols-2 gap-6">
                                <input
                                    required
                                    maxLength={256}
                                    pattern=".*\S+.*"
                                    title="This field is required."
                                    type="text"
                                    value={subscriberFirstName}
                                    onChange={e => {
                                        updateFields({ subscriberFirstName: e.target.value });
                                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                        } else {
                                            (e.target as HTMLInputElement).setCustomValidity('');
                                        }
                                    }}
                                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                    placeholder="Subscriber First Name"
                                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                                />

                                <input
                                    required
                                    maxLength={256}
                                    pattern=".*\S+.*"
                                    title="This field is required."
                                    type="text"
                                    value={subscriberLastName}
                                    onChange={e => {
                                        updateFields({ subscriberLastName: e.target.value });
                                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                        } else {
                                            (e.target as HTMLInputElement).setCustomValidity('');
                                        }
                                    }}
                                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                    placeholder="Subscriber Last Name"
                                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                                />
                            </div>

                            <TextDateField
                                required
                                value={subscriberDateOfBirth}
                                onChange={e => updateFields({ subscriberDateOfBirth: e })}
                                className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                label="Subscriber Date of Birth"
                                {...methods.registerField('subscriberDateOfBirth', {
                                    validate: (str) => {
                                        if (!RequiredValid(str)) {
                                            return 'This field is required.';
                                        }
                                        if (!DateFormatValid(str)) {
                                            return `Subscriber's date of birth is invalid.`;
                                        }
                                    },
                                    onBlurSetHtmlCustomValidity: true,
                                    validateOnBlur: true
                                })}
                            />
                        </>
                    )}
                </>
            )}

            {/* TODO: Needs regex or other improved way to match this */}
            {primaryPlan.toLowerCase().startsWith("veterans ccn") && (
                <>
                    <p className="mb-2 font-medium">This does not include Tricare, ChampVA, or other dependent military benefit programs.</p>
                    <input
                        type="text"
                        maxLength={128}
                        value={vaReferralNumber}
                        onChange={e => updateFields({ vaReferralNumber: e.target.value })}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Veterans Affairs (VA) Authorization/Referral Number"
                    />

                    <p className="mb-4 font-medium">
                        If you do not have a referral number, please reach out to your local
                        Women Veterans Program Maternity Care Coordinator
                    </p>
                    <div></div>

                    <p className="mb-2 font-medium">Optional: Please upload the front of your VA CCN Authorization/Referral document</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ vaCardFront: acceptedFiles[0] });
                            const errorElement = document.getElementById("va-front-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("va-front-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="va-front-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {vaCardFront && (
                            <>
                                {vaCardFront.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ vaCardFront: undefined })}>Remove</small>
                            </>
                        )}
                    </p>

                    <p className="mb-2 font-medium">Optional: Please upload the back of your VA CCN Authorization/Referral document</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ vaCardBack: acceptedFiles[0] });
                            const errorElement = document.getElementById("va-back-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("va-back-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="va-back-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {vaCardBack && (
                            <>
                                {vaCardBack.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ vaCardBack: undefined })}>Remove</small>
                            </>
                        )}
                    </p>
                </>
            )}

            <p className="mb-3">Is your insurance provided through an employer?</p>
            <div>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="primaryProvidedByEmployer"
                        value="yes"
                        checked={primaryProvidedByEmployer === "yes"}
                        onChange={e => updateFields({ primaryProvidedByEmployer: e.target.value })}
                    /> Yes
                </label>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="primaryProvidedByEmployer"
                        value="no"
                        checked={primaryProvidedByEmployer === "no"}
                        onChange={e => updateFields({ employer: "", employerAliasSelected: "", employerId: "", primaryProvidedByEmployer: e.target.value })}
                    /> No
                </label>
            </div>

            {primaryProvidedByEmployer === "yes" && (
                <>
                    <AsyncCreatableSelect
                        required
                        components={{ DropdownIndicator: null }}
                        loadOptions={promiseOptions}
                        onChange={option => {
                            if (option) {
                                let ft = formType;
                                const empIfKnown = employerOptions.find(x => x.label === option.label && x.value === option.value);

                                if (empIfKnown && formType !== 'vanity') {
                                    ft = 'nbf';
                                } else if (formType !== 'vanity') {
                                    ft = 'main';
                                }
                                if (empIfKnown) {
                                    updateFields({ employer: empIfKnown.employerAccountName, employerAliasSelected: empIfKnown.label, employerId: empIfKnown.value, formType: ft });
                                } else {
                                    updateFields({ employer: option.label, employerAliasSelected: option.label, employerId: "", formType: ft });
                                }
                            } else {
                                updateFields({ employer: "", employerAliasSelected: "", employerId: "", formType: "main" })
                            }
                        }
                        }
                        defaultValue={
                            employer ? {
                                label: employerAliasSelected,
                                value: employerId
                            } : null
                        }
                        noOptionsMessage={() => null}
                        formatCreateLabel={(inputValue) => (<>{inputValue}</>)}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 bg-transparent my-3"
                        styles={{
                            control: base => ({
                                ...base,
                                border: "0px solid black",
                                backgroundColor: "transparent",
                                outline: "none",
                                boxShadow: "none",
                            }),
                            valueContainer: base => ({
                                ...base,
                                padding: 0
                            }),
                            menu: base => ({
                                ...base,
                                width: "95%"
                            }),
                            option: (base, { isSelected, isFocused }) => ({
                                ...base,
                                backgroundColor: isFocused ? "lightblue" : "transparent",
                                color: "black"
                            })
                        }}
                        isClearable
                        placeholder="Employer name"
                    />

                    {/* TODO: Needs regex or other improved way to match this */}
                    {employerOptions.some((e: SelectOption) => e.label === employer) && (
                        <>
                            <p className="mb-3">Do you have a one-time code from an employer sponsor?</p>
                            <div>
                                <label className="mb-2">
                                    <input
                                        required
                                        type="radio"
                                        name="hasEmployerCode"
                                        value="yes"
                                        checked={hasEmployerCode === "yes"}
                                        onChange={e => updateFields({ hasEmployerCode: e.target.value })}
                                    /> Yes
                                </label>
                                <label className="mb-2">
                                    <input
                                        required
                                        type="radio"
                                        name="hasEmployerCode"
                                        value="no"
                                        checked={hasEmployerCode === "no"}
                                        onChange={e => updateFields({ hasEmployerCode: e.target.value })}
                                    /> No
                                </label>
                            </div>

                            {hasEmployerCode === "yes" && (
                                <>
                                    <input
                                        required
                                        maxLength={20}
                                        pattern=".*\S+.*"
                                        title="This field is required."
                                        type="text"
                                        value={employerCode}
                                        onChange={e => {
                                            updateFields({ employerCode: e.target.value });
                                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                            } else {
                                                (e.target as HTMLInputElement).setCustomValidity('');
                                            }
                                        }}
                                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                        placeholder="Employer Code"
                                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}

            {/* TODO: Might need regex or other improved way to match this */}
            {(!primaryPlan.toLowerCase().startsWith("veterans ccn")) && (
                <>
                    <p className="mb-3">Is baby on your primary insurance?</p>
                    <div>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="babyOnPrimary"
                                value="yes"
                                checked={babyOnPrimary === "yes"}
                                onChange={e => updateFields({ babyOnPrimary: e.target.value })}
                            /> Yes
                        </label>
                        <label className="mb-2">
                            <input
                                required
                                type="radio"
                                name="babyOnPrimary"
                                value="no"
                                checked={babyOnPrimary === "no"}
                                onChange={e => updateFields({ babyOnPrimary: e.target.value })}
                            /> No
                        </label>
                    </div>

                    {babyOnPrimary === "no" && (
                        <>
                            <Select
                                required
                                options={insOptions}
                                onChange={option => {
                                    if (option) {
                                        const babyProvider = insProviders.filter(p => p.id === option.value)[0];
                                        updateFields({
                                            babyInsurancePlan: option.label,
                                            babyInsurancePlanId: option.value,
                                            babyInsuranceNoTelehealth: babyProvider.noTelehealth
                                        })
                                    }
                                }}
                                defaultValue={
                                    babyInsurancePlan ? {
                                        label: babyInsurancePlan,
                                        value: babyInsurancePlanId
                                    } : null
                                }
                                className="rounded-full border-[#2D3C2C] border w-full px-4 bg-transparent my-3"
                                placeholder="Baby's Insurance Plan"
                                styles={{
                                    control: base => ({
                                        ...base,
                                        border: "0px solid black",
                                        backgroundColor: "transparent",
                                        outline: "none",
                                        boxShadow: "none",
                                    }),
                                    valueContainer: base => ({
                                        ...base,
                                        padding: 0
                                    }),
                                    menu: base => ({
                                        ...base,
                                        width: "95%"
                                    }),
                                    option: (base, { isSelected, isFocused }) => ({
                                        ...base,
                                        backgroundColor: isFocused ? "lightblue" : "transparent",
                                        color: "black"
                                    })
                                }}
                            />

                            <input
                                required
                                maxLength={40}
                                pattern=".*\S+.*"
                                title="This field is required."
                                type="text"
                                value={babyInsuranceNumber}
                                onChange={e => {
                                    updateFields({ babyInsuranceNumber: e.target.value });
                                    if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                        (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                    } else {
                                        (e.target as HTMLInputElement).setCustomValidity('');
                                    }
                                }}
                                className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                placeholder="Baby's Policy Number"
                                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                            />

                            <input
                                required
                                maxLength={40}
                                pattern=".*\S+.*"
                                title="This field is required."
                                type="text"
                                value={babyInsuranceGroup}
                                onChange={e => {
                                    updateFields({ babyInsuranceGroup: e.target.value });
                                    if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                        (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                    } else {
                                        (e.target as HTMLInputElement).setCustomValidity('');
                                    }
                                }}
                                className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                placeholder="Baby's Group Number"
                                onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                            />

                            <p className="mb-1">
                                Does this plan require a referral?
                                <a data-tooltip-id="referral-tooltip" data-tooltip-content="HMO and POS plans often require a referral" >
                                    <img src={HelpIcon} alt="Help" className="inline-block mt-1 h-[20px]" />
                                </a>
                            </p>
                            <div className="mb-3">
                                <label className="mb-2">
                                    <input
                                        required
                                        type="radio"
                                        name="babyInsuranceReferral"
                                        value="Yes"
                                        checked={babyInsuranceReferral === "Yes"}
                                        onChange={e => updateFields({ babyInsuranceReferral: e.target.value })}
                                    /> Yes
                                </label>
                                <label className="mb-2">
                                    <input
                                        required
                                        type="radio"
                                        name="babyInsuranceReferral"
                                        value="No"
                                        checked={babyInsuranceReferral === "No"}
                                        onChange={e => updateFields({ babyInsuranceReferral: e.target.value })}
                                    /> No
                                </label>
                            </div>

                            <div className="md:grid grid-cols-2 gap-6">
                                <input
                                    required
                                    maxLength={256}
                                    pattern=".*\S+.*"
                                    title="This field is required."
                                    type="text"
                                    value={babyInsuranceHolderFirstName}
                                    onChange={e => {
                                        updateFields({ babyInsuranceHolderFirstName: e.target.value });
                                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                        } else {
                                            (e.target as HTMLInputElement).setCustomValidity('');
                                        }
                                    }}
                                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                    placeholder="Baby's Policy Holder First Name"
                                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                                />

                                <input
                                    required
                                    maxLength={256}
                                    pattern=".*\S+.*"
                                    title="This field is required."
                                    type="text"
                                    value={babyInsuranceHolderLastName}
                                    onChange={e => {
                                        updateFields({ babyInsuranceHolderLastName: e.target.value });
                                        if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                            (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                                        } else {
                                            (e.target as HTMLInputElement).setCustomValidity('');
                                        }
                                    }}
                                    className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                    placeholder="Baby's Policy Holder Last Name"
                                    onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                                />
                            </div>

                            <TextDateField
                                required
                                value={babyInsuranceHolderDob}
                                onChange={e => updateFields({ babyInsuranceHolderDob: e })}
                                className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                                label="Baby's Policy Holder DOB"
                                {...methods.registerField('babyInsuranceHolderDob', {
                                    validate: (str) => {
                                        if (!RequiredValid(str)) {
                                            return 'This field is required.';
                                        }
                                        if (!DateFormatValid(str)) {
                                            return `Baby policy holders's date of birth is invalid.`;
                                        }
                                    },
                                    onBlurSetHtmlCustomValidity: true,
                                    validateOnBlur: true
                                })}
                            />

                            <p className="mb-2 font-medium">Please upload the front photo of baby's insurance card</p>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    updateFields({ babyInsuranceFront: acceptedFiles[0] });
                                    const errorElement = document.getElementById("baby-front-error");
                                    if (errorElement)
                                        errorElement.innerText = "";
                                }}
                                onDropRejected={(rejectedFiles) => {
                                    const errorElement = document.getElementById("baby-front-error");
                                    if (errorElement)
                                        errorElement.innerText = rejectedFiles[0].errors[0].message;
                                }}
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpg', '.jpeg'],
                                    'application/pdf': ['.pdf']
                                }}
                                maxSize={1024 * 1000 * 10}
                                minSize={1}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps({ required: babyInsuranceFront ? false : true })}
                                            style={{
                                                display: 'initial',
                                                opacity: '0',
                                                position: 'absolute',
                                                zIndex: -1
                                            }}
                                        />
                                        <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                            <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                            <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                                Select files
                                            </button>
                                        </div>
                                        <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                        <p id="baby-front-error" className="text-red-600"></p>
                                    </div>
                                )}
                            </Dropzone>
                            <p className="font-semibold mb-4">
                                {babyInsuranceFront && (
                                    <>
                                        {babyInsuranceFront.name}
                                        <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ babyInsuranceFront: undefined })}>Remove</small>
                                    </>
                                )}
                            </p>

                            <p className="mb-2 font-medium">Please upload the back of baby's insurance card</p>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    updateFields({ babyInsuranceBack: acceptedFiles[0] });
                                    const errorElement = document.getElementById("baby-back-error");
                                    if (errorElement)
                                        errorElement.innerText = "";
                                }}
                                onDropRejected={(rejectedFiles) => {
                                    const errorElement = document.getElementById("baby-back-error");
                                    if (errorElement)
                                        errorElement.innerText = rejectedFiles[0].errors[0].message;
                                }}
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpg', '.jpeg'],
                                    'application/pdf': ['.pdf']
                                }}
                                maxSize={1024 * 1000 * 10}
                                minSize={1}
                                multiple={false}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps({ required: babyInsuranceBack ? false : true })}
                                            style={{
                                                display: 'initial',
                                                opacity: '0',
                                                position: 'absolute',
                                                zIndex: -1
                                            }}
                                        />
                                        <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                            <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                            <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                                Select files
                                            </button>
                                        </div>
                                        <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                        <p id="baby-back-error" className="text-red-600"></p>
                                    </div>
                                )}
                            </Dropzone>
                            <p className="font-semibold mb-4">
                                {babyInsuranceBack && (
                                    <>
                                        {babyInsuranceBack.name}
                                        <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ babyInsuranceBack: undefined })}>Remove</small>
                                    </>
                                )}
                            </p>
                        </>
                    )}
                </>
            )}

            <p className="mb-3">Do you have a secondary insurance plan?</p>
            <div>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="haveSecondary"
                        value="yes"
                        checked={haveSecondary === "yes"}
                        onChange={e => updateFields({ haveSecondary: e.target.value })}
                    /> Yes
                </label>
                <label className="mb-2">
                    <input
                        required
                        type="radio"
                        name="haveSecondary"
                        value="no"
                        checked={haveSecondary === "no"}
                        onChange={e => updateFields({ haveSecondary: e.target.value })}
                    /> No
                </label>
            </div>

            {haveSecondary === "yes" && (
                <>
                    <Select
                        required
                        options={insOptions}
                        onChange={option => {
                            if (option)
                                updateFields({ secondaryPlan: option.label, secondaryPlanId: option.value })
                        }}
                        defaultValue={
                            secondaryPlan ? {
                                label: secondaryPlan,
                                value: secondaryPlanId
                            } : null
                        }
                        className="rounded-full border-[#2D3C2C] border w-full px-4 bg-transparent my-3"
                        placeholder="Secondary Insurance Provider"
                        styles={{
                            control: base => ({
                                ...base,
                                border: "0px solid black",
                                backgroundColor: "transparent",
                                outline: "none",
                                boxShadow: "none",
                            }),
                            valueContainer: base => ({
                                ...base,
                                padding: 0
                            }),
                            menu: base => ({
                                ...base,
                                width: "95%"
                            }),
                            option: (base, { isSelected, isFocused }) => ({
                                ...base,
                                backgroundColor: isFocused ? "lightblue" : "transparent",
                                color: "black"
                            })
                        }}
                    />

                    <input
                        required
                        maxLength={40}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={secondaryNumber}
                        onChange={e => {
                            updateFields({ secondaryNumber: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Secondary Insurance Policy Number"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />

                    <input
                        required
                        maxLength={40}
                        pattern=".*\S+.*"
                        title="This field is required."
                        type="text"
                        value={secondaryGroup}
                        onChange={e => {
                            updateFields({ secondaryGroup: e.target.value });
                            if (e.target.validity.valueMissing || e.target.validity.patternMismatch) {
                                (e.target as HTMLInputElement).setCustomValidity('This field is required.');
                            } else {
                                (e.target as HTMLInputElement).setCustomValidity('');
                            }
                        }}
                        className="rounded-full border-[#2D3C2C] border w-full px-4 py-2 bg-transparent my-3"
                        placeholder="Secondary Group Number"
                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('This field is required.')}
                    />

                    <p className="mb-2 font-medium">Please upload the front photo of your insurance card</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ secondaryFront: acceptedFiles[0] });
                            const errorElement = document.getElementById("secondary-front-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("secondary-front-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps({ required: secondaryFront ? false : true })}
                                    style={{
                                        display: 'initial',
                                        opacity: '0',
                                        position: 'absolute',
                                        zIndex: -1
                                    }}
                                />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="secondary-front-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {secondaryFront && (
                            <>
                                {secondaryFront.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ secondaryFront: undefined })}>Remove</small>
                            </>
                        )}
                    </p>

                    <p className="mb-2 font-medium">Please upload the back of your insurance card</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            updateFields({ secondaryBack: acceptedFiles[0] });
                            const errorElement = document.getElementById("secondary-back-error");
                            if (errorElement)
                                errorElement.innerText = "";
                        }}
                        onDropRejected={(rejectedFiles) => {
                            const errorElement = document.getElementById("secondary-back-error");
                            if (errorElement)
                                errorElement.innerText = rejectedFiles[0].errors[0].message;
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                            'application/pdf': ['.pdf']
                        }}
                        maxSize={1024 * 1000 * 10}
                        minSize={1}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps({ required: secondaryBack ? false : true })}
                                    style={{
                                        display: 'initial',
                                        opacity: '0',
                                        position: 'absolute',
                                        zIndex: -1
                                    }}
                                />
                                <div className="rounded-lg border border-dashed border-[#2D3C2C] text-center py-4 text-[16px] bg-[#F2F8EA] mb-2">
                                    <p className="mb-2 text-[#7E8288] font-medium">Drop files here or</p>
                                    <button className={`rounded-full bg-[#F8AC32] px-6 py-3 font-semibold border border-[#F8AC32] hover:bg-transparent`} type="button">
                                        Select files
                                    </button>
                                </div>
                                <em className="text-[#7E8288]">Accepted file types: jpg, png, pdf, jpeg, Max. file size: 10 MB, Max. files: 1.</em>
                                <p id="secondary-back-error" className="text-red-600"></p>
                            </div>
                        )}
                    </Dropzone>
                    <p className="font-semibold mb-4">
                        {secondaryBack && (
                            <>
                                {secondaryBack.name}
                                <small className="underline pl-2 cursor-pointer" onClick={() => updateFields({ secondaryBack: undefined })}>Remove</small>
                            </>
                        )}
                    </p>
                </>
            )}
            <Tooltip id="referral-tooltip" />
        </FormWrapper>
    )
}