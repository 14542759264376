/**
 * Gets age based off date of birth, subtracting today's date against a date string.
 * @param dateStr The date of birth, formatted as YYYY-MM-DD.
 */
export function GetAge(dateStr: string): number {
    const today = new Date(Date.now());
    const date = new Date(dateStr + "T00:00:00");
    let years = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < date.getDate()))
        years--;

    return years;
}

/**
 * Validates that a date's format is valid. Currently only validates YYYY-MM-DD.
 * @param date The date to validate, formatted as YYYY-MM-DD.
 */
export function DateFormatValid(date: string): boolean {
    const segs = date.split('-')
    if (segs[0].length !== 4 || segs[1].length !== 2 || segs[2].length !== 2)
        return false
    else
        return true
}

export function RequiredValid(str: string): boolean {
    if (!str.trim()) {
        return false
    } else {
        return true
    }
}