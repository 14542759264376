export type ValidatorFunction = (value: string) => string | undefined;

export function createValidatorControl<T>() {
    let registeredFields: { [key in keyof T]?: { validate: ValidatorFunction, ref?: HTMLInputElement } } = {};

    const set = (name: keyof T, validate: ValidatorFunction, ref: HTMLInputElement) => {
        registeredFields[name] = { validate: validate, ref: ref };
    }

    function getAll() {
        return registeredFields;
    }

    function get(name: keyof T) {
        return registeredFields[name];
    }

    function clear() {
        registeredFields = {};
    }

    function remove(name: keyof T) {
        registeredFields[name] = undefined;
    }

    return { get, getAll, set, clear, remove }
}