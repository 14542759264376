import { ReactNode } from "react";

type FormWrapperProps = {
    title: string
    children: ReactNode
}

export function FormWrapper({ title, children }: FormWrapperProps) {
    return (
        <>
            {title && <h3 className="lg:text-3xl text-xl lg:py-8 py-4 font-semibold text-primary-green">
                {title}
            </h3>}
            <div className="form-container">
                {children}
            </div>
        </>
    )
}