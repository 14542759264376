import { useEffect } from "react";
import { HelpIcon } from "./images/index";

export function ThankYou({ myuuid, formType }: { myuuid: string, formType: string }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-[#EFFFE9] lg:pt-8 rounded-3xl lg:p-12 p-4 shadow-xl min-h-[420px]">
            <h2 className="font-500 color-[#273B29] lg:text-2xl text-lg lg:mt-6 mt-2 mb-8">Thank you!</h2>
            <p className="lg:text-[16px] text-[14px] pr-4">
                {formType === "vanity" ? (
                    <>
                        We will verify your information soon. If your need is urgent, please send us an
                        email at <a href="mailto:care@tln.care">care@tln.care</a> with "Urgent" in the subject
                        line and we will address your concerns as soon as possible.
                    </>
                ) : (
                    <>
                        We will verify your information and connect you to an IBCLC near you soon. If your
                        need is urgent, please send us an email at <a href="mailto:care@tln.care">care@tln.care</a> with
                        "Urgent" in the subject line and we will address your concerns as soon as possible.
                    </>
                )}
            </p>

            <p className="text-[16px] mt-4">
                Submission ID: {myuuid}
            </p>

            <div className="bg-[#DAE7D0] p-4 rounded-xl text-[16px] mt-8">
                <img src={HelpIcon} alt="Help" className="inline-block pr-2 pt-1" />
                If you have any questions or concerns, please email us at <a href="mailto:care@tln.care">care@tln.care</a>.
            </div>

            {formType === "vanity" && (
                <p className="mt-4">Please share your feedback with us. <a href="https://tlnpx.qualtrics.com/jfe/form/SV_5u5JNbLwKqIH12K" className="underline">Click Here</a></p>
            )}

            {formType === "main" && (
                <p className="mt-4">Please share your feedback with us. <a href="https://tlnpx.qualtrics.com/jfe/form/SV_71VUCNRb9PMKpsW" className="underline">Click Here</a></p>
            )}
        </div>
    )
}