import { PropsWithChildren, createContext, useContext, useMemo, useState } from "react";
import { ValidatorFunction, createValidatorControl } from "../logic/createValidatorControl";

type Options = {
    validate: ValidatorFunction,
    validateOnBlur: boolean;
    executeOnBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    onBlurSetHtmlCustomValidity?: boolean;
}

type Errors<T> = { [key in keyof T]?: string }

type ValidatorReturn<T> = {
    errors: Errors<T>;
    registerField: (name: keyof T, options: Options) => {
        onBlur: ((e: React.FocusEvent<HTMLInputElement, Element>) => void) | undefined;
        ref: (ref: HTMLDivElement | null) => void;
    };
    isValid: boolean;
    validate: (key?: keyof T) => boolean;
}

export function useValidator<T>(data: T): ValidatorReturn<T> {
    const { get, getAll, set } = createValidatorControl<T>();
    const [errors, setErrors] = useState<Errors<T>>({});
    const isValid = useMemo(() => Object.keys(errors).length > 0, [errors]);

    /**
     * Validate all registered fields, or one specific field if a key is passed.
     * @param key One specific key to validate.
     * @returns {boolean} Returns true if valid, false if not. 
     */
    function validate(key?: keyof T, setErrorMessage?: (e: string) => void): boolean {
        if (key) {
            const rf = get(key);

            if (rf) {
                const str = get(key)?.validate(data[key] as string);
                if (str) {
                    if (setErrorMessage) {
                        setErrorMessage(str);
                    }
                    setErrors({ ...errors, ...{ [key]: str } });
                    return false;
                } else {
                    if (setErrorMessage) {
                        setErrorMessage('');
                    }
                    setErrors({ ...errors, ...{ [key]: undefined } });
                    return true;
                }
            }
        } else {
            setErrors({});
            let valid = true;
            let errs: Errors<T> = {};
            Object.keys(getAll()).forEach(r => {
                const rf = get(r as keyof T);
                if (rf) {
                    const str = rf.validate(data[r as keyof T] as string);
                    rf.ref?.setCustomValidity(str ?? '');
                    rf.ref?.reportValidity();
                    if (str) {
                        errs = { ...errs, ...{ [r]: str } }
                        valid = false;
                    }
                }
            })
            setErrors(errs);
            return valid;
        }
        return false;
    }

    /**
     * Register a field for validation on an input.
     * @param name Name of registered field.
     * @param options Options of registered field, including the validation logic.
     * @returns onBlur, ref property will be returned.
     */
    function registerField(name: keyof T, options: Options): {
        onBlur: ((e: React.FocusEvent<HTMLInputElement, Element>) => void) | undefined,
        ref: (ref: HTMLDivElement | null) => void
    } {
        return {
            ref: ref => set(name, options.validate, (ref as HTMLDivElement)?.firstChild as HTMLInputElement),
            onBlur: options.validateOnBlur ? (e) => {
                if (options.executeOnBlur) {
                    options.executeOnBlur(e);
                }
                validate(name, options.onBlurSetHtmlCustomValidity ? (msg) => e.target.setCustomValidity(msg) : undefined);
            } : options.executeOnBlur
        };
    };

    return { errors, isValid, registerField, validate }
}

const ValidatorContext = createContext<unknown | null>(null);

interface ValidatorProviderProps {
    methods: unknown
}

export const ValidatorProvider: React.FC<PropsWithChildren & ValidatorProviderProps> = ({ children, methods }) => {
    return (
        <ValidatorContext.Provider value={methods}>
            {children}
        </ValidatorContext.Provider >
    )
}

export function useValidatorContext<T>() {
    return useContext(ValidatorContext) as ValidatorReturn<T>;
}