import { ReactElement, useState } from 'react';

export function useMultistepForm(steps: ReactElement[], validate?: () => boolean) {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [validateFailed, setValidateFailed] = useState(false);

    function next() {
        if (!validate || validate())
            setCurrentStepIndex(i => {
                if (i >= steps.length - 1) return i;
                return i + 1;
            })
    }

    function back() {
        setCurrentStepIndex(i => {
            if (i <= 0) return i;
            return i - 1;
        })
    }

    function goTo(index: number) {
        setCurrentStepIndex(index);
    }

    return {
        currentStepIndex,
        step: steps[currentStepIndex],
        steps,
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        validateFailed,
        goTo,
        next,
        back
    }
}