import { FooterLogo, SslImage, HsaFsaImage } from "../images/index";

export function Footer() {
    return (
        <footer className="bg-footer w-full m-0 xl:px-60 px-4 pt-20 pb-4 bg-no-repeat" style={{
            backgroundSize: "155px auto,204px auto",
            backgroundPosition: "100% 0,bottom -72px left"
        }}>
            <div className="container mx-auto text-[14px] uppercase">
                <a href="https://lactationnetwork.com/">
                    <img src={FooterLogo} alt="TLN" className="md:w-[180px] w-[120px]" />
                </a>

                <div className="grid md:grid-cols-5 gap-4 md:py-20 py-12 text-[#EFFFE9]">
                    <div>
                        <p className="title text-[#C7D8C2] mb-2">Our Company</p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/about-us/">About Us</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/contact-us/">Contact Us</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/press/">Press</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/careers/">Careers</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/frequently-asked-questions">FAQ</a>
                        </p>
                    </div>
                    <div>
                        <p className="title text-[#C7D8C2] mb-2">Services</p>
                        <p className="mb-1">
                            <a href="/">Lactation Consultation</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/for-health-plans/">For Health Plans</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/breastfeeding-resources/">Breastfeeding Resources</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/advocacy-hub/">Lactation Coverage Gap</a>
                        </p>
                    </div>
                    <div>
                        <p className="title text-[#C7D8C2] mb-2">Social</p>
                        <p className="mb-1">
                            <a href="https://www.facebook.com/LactationNetwork/">Facebook</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://www.instagram.com/lactationnetwork/">Instagram</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://www.pinterest.com/thelactationnetwork/">Pinterest</a>
                        </p>
                    </div>
                    <div>
                        <p className="title text-[#C7D8C2] mb-2">&nbsp;</p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/privacy-policy/">Privacy Policy</a>
                        </p>
                        <p className="mb-1">
                            <a href="https://lactationnetwork.com/hipaa/">HIPAA</a>
                        </p>
                    </div>
                </div>

                <div className="copyright text-[#C7D8C2] md:grid grid-cols-2">
                    <div>
                        <p>
                            &copy; 2024 TLN, Inc. &ndash; All rights reserved.
                        </p>
                        <p className="text-[9px]">
                            THE LACTATION NETWORK, TLN, and TLN stylized are trademarks of The Lactation Network, LLC.
                        </p>
                    </div>
                    <div>
                        <img src={HsaFsaImage} className="float-right ml-16 w-[100px]" alt="HSA FSA" />
                        <img src={SslImage} className="float-right w-[100px]" alt="SSL" />
                    </div>
                    <div className="clear-both"></div>
                </div>
            </div>
        </footer>
    )
}