import { FormWrapper } from "./components/FormWrapper";
import { useEffect } from "react";

type StepFourProps = {
    firstName: string
    lastName: string
    primaryPlan: string
    urgency: string
    careLocation: string
    coverageInformation: string | null
    vanityName: string
    formType: string
}

export function StepFourForm({
    firstName,
    lastName,
    primaryPlan,
    urgency,
    careLocation,
    coverageInformation,
    vanityName,
    formType,
}: StepFourProps) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormWrapper title="Confirm your information">
            <h4 className="lg:text-2xl text-lg lg:mt-6 mt-2 mb-2 font-semibold" autoFocus>Please carefully review the information you have provided before submitting</h4>

            <div className="md:grid grid-cols-4 gap-4 py-4">
                <div className="text-[#7E8288] font-medium">Lactating parent</div>
                <div className="text-[#333333] col-span-3 font-semibold">{firstName} {lastName}</div>
            </div>

            <div className="md:grid grid-cols-4 gap-4 py-4 border-t border-t-[#C2C2C2]">
                <div className="text-[#7E8288] font-medium">Insurance plan</div>
                <div className="text-[#333333] col-span-3 font-semibold">{primaryPlan}</div>
            </div>

            <div className="md:grid grid-cols-4 gap-4 py-4 border-t border-t-[#C2C2C2]">
                <div className="text-[#7E8288] font-medium">Urgency preference</div>
                <div className="text-[#333333] col-span-3 font-semibold">{urgency}</div>
            </div>

            {formType !== "vanity" && (
                <div className="md:grid grid-cols-4 gap-4 py-4 border-t border-t-[#C2C2C2]">
                    <div className="text-[#7E8288] font-medium">Location preference</div>
                    <div className="text-[#333333] col-span-3 font-semibold">{careLocation}</div>
                </div>
            )}

            {coverageInformation && (
                <div className="md:grid grid-cols-4 gap-4 py-4 border-t border-t-[#C2C2C2]">
                    <div className="text-[#7E8288] font-medium">Important information about your coverage</div>
                    <div className="text-[#333333] col-span-3 font-semibold">
                        {coverageInformation}
                    </div>
                </div>
            )}

            {vanityName && (
                <div className="md:grid grid-cols-4 gap-4 py-4 border-t border-t-[#C2C2C2]">
                    <div className="text-[#7E8288] font-medium">You are paired with</div>
                    <div className="text-[#333333] col-span-3 font-semibold">
                        {vanityName}
                    </div>
                </div>
            )}
        </FormWrapper>
    )
}