import { useEffect } from "react";
import { HelpIcon } from "./images";
import { ArrowIcon } from "./images/index";

export function ErrorPage({ myuuid, navBack }: { myuuid: string, navBack: () => void }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-[#EFFFE9] lg:pt-8 rounded-3xl lg:p-12 p-4 shadow-xl min-h-[420px]">
            <h2 className="font-500 color-[#273B29] lg:text-2xl text-lg lg:mt-6 mt-2 mb-8">We're sorry</h2>
            <p className="lg:text-[16px] text-[14px] pr-4 mb-4">
                An unexpected error occurred while processing your submission. Please contact us by calling <span className="whitespace-nowrap font-bold">&#40;888&#41; 510-0059</span> or emailing <a className="whitespace-nowrap font-bold" href="mailto:care@tln.care">care@tln.care</a>.
            </p>
            <p className="lg:text-[16px] pr-4 mb-4">
                To assist us in resolving the issue, please have your submission ID <span className="whitespace-nowrap font-bold">{myuuid}</span> available for reference.
            </p>
            <p className="lg:text-[16px] pr-4 mb-4">
                Or try again later.
            </p>
            <button className="block rounded-full px-6 py-3 font-semibold border border-[#F8AC32] md:w-auto w-full md:mb-0 mb-2 mt-4" type="button" onClick={navBack}>
                <img src={ArrowIcon} alt="Back" className="inline-block relative bottom-px pl-4 scale-x-[-1]" />
                Try again
            </button>

            <div className="bg-[#DAE7D0] p-4 rounded-xl text-[16px] mt-8">
                <img src={HelpIcon} alt="Help" className="inline-block pr-2 pt-1" />
                Questions? Email us at <a href="mailto:care@tln.care">care@tln.care</a>.
            </div>
        </div>
    )
}