import { Logo } from "../images/index";

export function Header() {
    return (
        <div className="md:container md:mx-auto mx-2 md:pt-8 pt-4 grid grid-cols-2">
            <div>
                <a href="https://lactationnetwork.com/">
                    <img src={Logo} alt="TLN" className="inline md:w-[188px] w-[120px] mt-[11px]" />
                </a>
            </div>
            <div>
                <a href="https://lactationnetwork.com/" className="float-right pt-4 text-[#273B29]">
                    &lt;- Back to main site
                </a>
            </div>
        </div>
    )
}